import { Outlet } from 'react-router-dom';

export const Content = () => {
  return (
    <div className='container'>
      <div className='app-content-full-height'>
        <div id="content" className="app-content d-flex flex-column p-0">
          <div className="app-content-padding flex-grow-1 overflow-hidden" data-scrollbar="true" data-height="100%">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
