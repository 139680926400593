import { Content } from '../../components/content/content';
import { Header } from '../../components/header/header';
import { Footer } from '../footer/footer';

//Bootstrap
import 'bootstrap';

//CSS
import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import '../../index.css';

export const Layout = () => {
  return (
    <div className='app'>
      <Header />
      <Content />
      <Footer />
    </div>
  );
}
