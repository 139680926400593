import { createBrowserRouter } from "react-router-dom";

import { Layout } from "./components/Layout";
import { Client } from "./pages/Client/client";
import { Home } from "./pages/Home/home";
import { Login } from "./pages/Login/login";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      }
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/client",
    element: <Client />
  }
]);

export { router };
