import Logo from '../../assets/logo/logo.png';

export const Header = () => {
  //const signed = false

  return (
    <header className="app-header-fixed app-sidebar-fixed">
      <div className="app-header bg-dark text-white py-3">
        <div className="navbar-header">
          <a href="/" className="navbar-brand">
            <img width="266" src={Logo} alt="Logo" />
          </a>
        </div>

        {/* <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start debug"> */}
        {/* <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <img width="266" src={Logo} alt="Logo" />
          </a> */}
        {/* <TopMenu /> */}
        {/* <Search /> */}

        {/* {signed && (
            <TopMenuLogged />
          )
          }

          {!signed && (
            <div className="p-3 p-md-4 rounded-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 60"></path>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"></path>
              </svg>
            </div>
          )
          } */}
        {/* </div> */}
      </div>
    </header>
  );
}
